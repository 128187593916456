/* Global CSS styles */

body {
  margin: 0;
  background-color: rgb(250, 237, 237) !important;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 40px;
}

.container {
  max-width: 800px; /* Adjust this value to your desired width */
  margin: 0 auto;
  padding-left: 40px; /* Adjust padding as needed */
  padding-right: 40px;
}

@media (max-width: 768px) {
  body {
    padding: 20px; /* Reduced padding for tablets and smaller devices */
  }
}

@media (max-width: 480px) {
  body {
    padding: 10px; /* Even smaller padding for mobile phones */
  }
}

.hi {
  font-size: 400%;
  color: navy;
  max-width: 1000px; /* Adjust this value to your desired width */
  margin: 0 auto;
  padding-left: 60px; /* Adjust padding as needed */
  padding-right: 40px;
}

p {
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

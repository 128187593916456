
/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    background-color: rgb(250, 237, 237);
    padding: 40px 30px;
    color: navy;
    display: flex;
    justify-content: space-between;
}

nav {
    display: flex;
    justify-content: flex-end;
    gap: 2em;
}

.nav-link {
    color: navy;
    text-decoration: none;
}

.nav-link:active {
    color: white !important;
}

.nav-link:hover {
    color: #c14c44 !important;
}

.stamp-container {
    display: flex;
    align-items: flex-start;
}

.stamp {
    margin-top: 0;
    padding-top: 0;
    height: 60px;
}

.icon {
    width: 20px;
    height: 20px;
    fill:navy
}

.icon:link {
    fill: navy !important;
}

.icon:hover{
    fill: #c14c44;
}
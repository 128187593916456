.card {
    width: 100%; /* Use full width */
    max-width: 400px; /* Ensure maximum width */
    margin: 10px; /* Margin around the card */
    border: 2px solid rgb(64, 64, 126) !important; /* Card border */
    display: flex; /* Flexbox layout for uniform height */
    flex-direction: column; /* Stack child elements vertically */
}

.card-body {
    background-color: rgb(64, 64, 126);
    color: white;
    flex-grow: 1; /* Allow the body to grow and fill space */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack content vertically */
    justify-content: space-between; /* Space between title and description */
    padding: 15px; /* Add padding */
    min-height: 150px; /* Minimum height */
}

.card-img {
    height: 200px; /* Fixed height for images */
    width: 100%; /* Full width for images */
    object-fit: cover; /* Cover the area while maintaining aspect ratio */
    object-position: top; /* Position the image */
}

.project-card-container {
    display: flex; /* Use flexbox */
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: flex-start; /* Align items at the start */
    gap: 20px; /* Space between cards */
}

.p-container {
    max-width: 1000px; /* Adjust this value to your desired width */
    margin: 0 auto;
    padding-left: 40px; /* Adjust padding as needed */
    padding-right: 40px;
    padding-top: 30px;
}

.p-subtitle {
    color: navy;
    padding-top: 30px;
    padding-bottom: 20px;
}

.p-description {
    padding: 20px;
    color: navy;
}

.p-strong {
    color: #c14c44;
    padding-bottom: 20px !important;
}

.p-imgbox {
    width: 400px;
    height: 300px;
    padding: 20px;
    padding-top: 30px;
    overflow: hidden;
}
.p-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.process-img {
    width: 350px;
}

.figma-img {
    width: 200px;
    margin-right: 10px;
}

.p-image2 {
    width: 400px;
    padding: 20px;
}

.big-hi {
    font-size: 400%;
    color: navy;
    padding-left: 160px;
}

.p-hi {
    font-size: 400%;
    color: navy;
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 40px; 
    padding-right: 40px;
}

.p-img-container {
    display: flex;
    gap: 20px; /* Optional: Add space between the images */
}

.p-h3 {
    color: navy;
}

.project {
    padding-bottom: 50px;
}
.background {
    background-color: rgb(250, 237, 237);

}

.home-title {
    color: navy;
    display: flex;
    flex-direction: column;
}

.about-btn-container {
    padding-left: 20px;
    padding-top: 40px;
    margin-right: 10px;
}

.about-btn {
    color: navy !important;
    border-color: navy !important;
    height: 50px;
    width: 200px;
    font-size: large !important;
    margin-right: 10px;
}

.about-btn:hover {
    color: white !important;
    background-color: navy !important;
}

.container {
    display: flex;
    justify-content: center;
}

.headshot {
    height: 500px;
    padding: 20px;
    align-items: flex-start;
}

.hi-2 {
    font-size: 500%;
    padding: 20px;
}

.hi-3 {
    font-size: 400%;
    padding: 20px;
    color: navy;
}

.subtitle {
    padding-left: 20px;
    font-weight: 400;
}

.subtitle-2 {
    padding-left: 20px;
    padding-top: 40px;
}

.icon-container {
    justify-content: center;
    justify-content: space-between;
}

.icon {
    height: 200px;
}

.cust-link {
    color: navy;
}

.cust-link:hover {
    color: #c14c44;
}
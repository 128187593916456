

@media (max-width: 768px) {
    .slick-slide img {
      width: 50%;
    }
  }


.carousel-image {
  width: 90%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

.slick-prev:before {
  color: navy !important; 
}

.slick-next:before {
  color: navy !important; 
}
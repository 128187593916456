.a-container {
    max-width: 1000px; /* Adjust this value to your desired width */
    margin: 0 auto;
    padding-left: 40px; /* Adjust padding as needed */
    padding-right: 40px;
    color: navy;
}

.btn-container {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.btn-outline {
    width: 100px;
    color: navy !important;
    border-color: navy !important;
    background-color: transparent !important;
}

.btn-outline:hover {
    color: white !important; 
    border-color: navy !important;
    background-color: navy !important;
}

.btn-outline.active {
    color: white !important;
    background-color: navy !important;
}

.bullet-list {
    padding-left: 50px;
    padding-right: 50px;
}

.about-box {
    background-color: rgb(250, 177, 128) !important;
    color: black !important;
    padding: 0.5em;
    border-radius: 10px;
    text-decoration: none; /* Remove underline */
}

.btngrp-pos {
    max-width: 1000px; /* Adjust this value to your desired width */
    margin: 0 auto;
    padding-left: 60px; /* Adjust padding as needed */
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.bigtext {
    color: navy;
}

.arrow-subtitle {
    color: navy;
    font-weight: bold;
    font-size: large;
}

.p-big {
    font-size: large;
}

.arrow-bullets {
    font-size: large;
    padding-bottom: 10px;
}
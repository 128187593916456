.card-box {
    width: 18rem;
}

.project-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Adjust to align items properly */
    gap: 20px; /* Space between cards */
}
